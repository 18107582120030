import gql from 'graphql-tag';

/**
 * Product Priced Option
 */
const ProductPricedOptionFields = gql`
	fragment ProductPricedOptionFields on ProductPricedOption {
		id
		label
		type
		image {
			id
			imageType
			description
		}
		value
		price
		variantId
		validator
		invalidMessage
	}
`;

/**
 * Product Priced Option Group
 */
export const ProductPricedOptionGroupFields = gql`
	fragment ProductPricedOptionGroupFields on ProductPricedOptionGroup {
		title
		required
		description
		defaultImage {
			id
			imageType
			description
		}
		defaultOption
		pricedOptions {
			...ProductPricedOptionFields
		}
	}
	${ProductPricedOptionFields}
`;

/**
 * Keycode Validation Query
 */
export const VALIDATE_KEYCODE = gql`
	query validateKeyCode($keyCode: String!, $variantId: Int!) {
		validateKeyCode(keyCode: $keyCode, variantId: $variantId) {
			code
		}
	}
`;
