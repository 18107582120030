import gql from 'graphql-tag';

/**
 * Product Attribute Group Fields
 */
export const ProductAttributeGroupFields = gql`
	fragment ProductAttributeGroupFields on ProductAttributeGroup {
		name
		attributes {
			id
			name
			values
			units
			description
			isHidden
			featured
		}
	}
`;
